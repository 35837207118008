<!-- Date picker custom từ flatpickr, áp dụng được với validate: nhưng phải có thêm input hidden -->
<template lang="">
  <b-form-group
    :id="`form-group-${id}`"
    :label-for="id"
  >
    <template
      v-if="label"
      #label
    >
      {{ label }}
      <span
        v-if="required"
        class="text-danger"
      >(*)</span>
    </template>
    <!-- Input hidden -->
    <!-- <BFormInput
      :id="id"
      :value="date"
      class="d-none"
    /> -->
    <div
      :id="`div-${id}`"
      :class="`form-control ${error ? 'is-invalid' : ''} p-0 ${customClass} ${disabled ? 'bg-gray' : ''}`"
    >
      <!-- :id="id" -->
      <flat-pickr
        :value="date"
        class=""
        :disabled="disabled"
        :config="{
          allowInput: true,
          dateFormat: 'Y-m-d',
          altFormat: 'd/m/Y',
          altInput: true,
          locale: $i18n.locale === 'vi' ? Vietnamese : null,
          disableMobile: true,
          altInputClass: 'form-control input px-50 py-0 border-transparent height-35px',
          ...configs,
        }"
        v-bind="[$attrs]"
        @input="onInputHandle"
      />
    </div>

    <b-tooltip
      v-if="error"
      variant="danger"
      boundary="window"
      :target="`div-${id}`"
      triggers="hover"
    >
      {{ error }}
    </b-tooltip>
  </b-form-group>
</template>
<script>
import { BFormGroup, BTooltip } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

export default {
  components: {
    BTooltip,
    BFormGroup,
    flatPickr,
  },
  props: {
    id: { type: String, required: true },
    date: { type: String, required: true },
    label: { type: String, default: '' },
    required: { type: Boolean, default: false },
    error: { type: String, default: '' },
    configs: { type: Object, default: () => ({}) },
    customClass: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  setup(_, { emit, listeners }) {
    function onInputHandle(val) {
      if (listeners.input) {
        listeners.input(val)
        return
      }
      emit('update:date', val)
    }
    return {
      Vietnamese, onInputHandle,
    }
  },
}
</script>
<style lang="">

</style>
