var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-group', {
    attrs: {
      "id": "form-group-".concat(_vm.id),
      "label-for": _vm.id
    },
    scopedSlots: _vm._u([_vm.label ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.required ? _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("(*)")]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }, [_c('div', {
    class: "form-control ".concat(_vm.error ? 'is-invalid' : '', " p-0 ").concat(_vm.customClass, " ").concat(_vm.disabled ? 'bg-gray' : ''),
    attrs: {
      "id": "div-".concat(_vm.id)
    }
  }, [_c('flat-pickr', _vm._b({
    attrs: {
      "value": _vm.date,
      "disabled": _vm.disabled,
      "config": Object.assign({
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true,
        altInputClass: 'form-control input px-50 py-0 border-transparent height-35px'
      }, _vm.configs)
    },
    on: {
      "input": _vm.onInputHandle
    }
  }, 'flat-pickr', [_vm.$attrs], false))], 1), _vm.error ? _c('b-tooltip', {
    attrs: {
      "variant": "danger",
      "boundary": "window",
      "target": "div-".concat(_vm.id),
      "triggers": "hover"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }